import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/page.less'
import Logo from '../../images/logo.svg'
import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/share.jpg'

const DESCRIPTION =
  '100% способы и схемы заработка на ипотечных кредитах в 2020 году.Узнай как заработать на перепродаже, сдаче или сделке по ипотеке!'

const HowToMakeMoneyOnMortgage = () => {
  return (
    <>
      <Helmet>
        <title>
          Как зарабатывать на ипотеке в 2020 году - схемы заработка на сделках
          по ипотечным кредитам
        </title>

        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>

      <div className="page">
        <div className="page--header">
          <a href="/" target="_blank">
            <img src={Logo} alt={'logo'} loading="lazy" />
          </a>
        </div>
        <article className="page--content">
          <h1>Как можно заработать на ипотеке</h1>
          <p className="page--main-p">
            В&nbsp;этой статье мы&nbsp;поговорим не&nbsp;только о&nbsp;заработке
            на&nbsp;ипотеке для риэлторов и&nbsp;ипотечных брокеров. Но&nbsp;ещё
            и&nbsp;о&nbsp;том, как сделать ипотеку более финансово
            привлекательной для ваших клиентов. Многим из&nbsp;них кажется, что
            ипотека&nbsp;&mdash; это только расходы, какой здесь может быть
            доход? Но&nbsp;на&nbsp;самом деле заработать на&nbsp;ипотечном
            кредите вполне возможно, и&nbsp;при грамотном расчёте это вполне
            выгодно. Вы&nbsp;можете дать советы, изложенные ниже, своим
            клиентам, заработать самому и&nbsp;помочь заработать&nbsp;им. Суть
            в&nbsp;том, что человек зарабатывает на&nbsp;различных операциях
            с&nbsp;недвижимостью. Но&nbsp;при этом ему вовсе не&nbsp;обязательно
            иметь в&nbsp;собственности такую недвижимость или покупать квартиру
            сразу&nbsp;&mdash; можно взять ипотеку. В&nbsp;итоге
            он&nbsp;получает доход при гораздо меньших начальных вложениях (чаще
            всего только первоначальный взнос и&nbsp;минимум платежей).
          </p>
          <section className="page--section">
            <h2>Инвестирование в новостройку</h2>
            <p>
              Один из&nbsp;самых известных способов заработка
              на&nbsp;ипотеке&nbsp;&mdash; это приобретение квартиры
              в&nbsp;новостройке в&nbsp;рассрочку на&nbsp;этапе строительства.
              Её&nbsp;стоимость в&nbsp;этот момент минимальна. Позже, когда дом
              будет сдан, квартиру можно продать дороже, досрочно погасив
              ипотеку и&nbsp;оставшись в&nbsp;выигрыше.
            </p>
            <p>
              <img
                src={img1}
                alt="Инвестирование в новостройку"
                loading="lazy"
              />
            </p>
            <p className="ul-header">Что нужно учесть: </p>
            <ul>
              <li>
                надёжность застройщика. Необходимо тщательно проверить, сколько
                объектов уже было сдано этой компанией в&nbsp;данном городе,
                сдавались они в&nbsp;срок или с&nbsp;задержкой,
                стабильно&nbsp;ли их&nbsp;финансовое положение, сколько лет
                существуют и&nbsp;т.&nbsp;п.;
              </li>
              <li>
                привлекательность квартиры для продажи: расположение, близость
                инфраструктуры, перспективность района, планировка
                и&nbsp;т.&nbsp;п.;
              </li>
              <li>
                лучше брать в&nbsp;ипотеку однокомнатную квартиру или
                студию&nbsp;&mdash; на&nbsp;них больше спрос;
              </li>
              <li>
                в&nbsp;ипотечном договоре должно быть условие, что квартиру
                можно продать до&nbsp;полной выплаты долга;
              </li>
              <li>
                оформлять приобретение нужно по&nbsp;договору долевого
                участия&nbsp;&mdash; это обезопасит владельца от&nbsp;многих
                неприятных сюрпризов со&nbsp;стороны застройщика, например,
                повышения цены квартиры при сдаче дома;
              </li>
              <li>
                нужно будет заплатить&nbsp;13% налога от&nbsp;разницы между
                суммой покупки и&nbsp;суммой сделки по&nbsp;продаже&nbsp;&mdash;
                это нужно учесть при расчете рентабельности;
              </li>
              <li>
                продавать недвижимость лучше до&nbsp;вступления
                в&nbsp;собственность: так владелец избежит лишних расходов.
              </li>
            </ul>
            <p>
              Кстати, вы&nbsp;можете предложить своему клиенту услуги оценки
              надежности застройщика и&nbsp;привлекательности квартиры для
              продажи.
            </p>
            <p className="ul-header">Какие существуют риски:</p>
            <ul>
              <li>
                квартиру, которая находится в&nbsp;залоге, сложнее продать;
              </li>
              <li>
                сроки сдачи могут затянуться, и&nbsp;тогда заёмщик будет нести
                дополнительные убытки в&nbsp;виде процентов по&nbsp;ипотечному
                кредиту;
              </li>
              <li>
                застройщик может обанкротиться, новостройку будут передавать для
                завершения строительства другой фирме (это в&nbsp;лучшем
                случае), и&nbsp;все это может затянуться надолго, как
                и&nbsp;выплаты вашего клиента по&nbsp;ипотеке;
              </li>
              <li>
                дом или приобретенная квартира могут быть сданы
                с&nbsp;дефектами, которые понизят её&nbsp;стоимость
                на&nbsp;рынке, или&nbsp;же придется вкладывать деньги
                в&nbsp;их&nbsp;исправление.
              </li>
            </ul>
          </section>
          <section className="page--section">
            <h2>Квартира на вторичном рынке</h2>
            <p>
              Похожая схема и&nbsp;с&nbsp;квартирами на&nbsp;вторичном рынке.
              Только здесь человеку нужно взять в&nbsp;ипотеку готовую, жилую
              квартиру, сделать в&nbsp;ней ремонт&nbsp;&mdash; и&nbsp;продать
              дороже. Обычно это квартиры с&nbsp;хорошим расположением (недалеко
              от&nbsp;метро, в&nbsp;историческом центре, в&nbsp;тихих зеленых
              районах и&nbsp;т.&nbsp;д.), в&nbsp;хороших добротных домах,
              но&nbsp;в&nbsp;&laquo;убитом&raquo; состоянии. После ремонта
              их&nbsp;цена существенно вырастает.
            </p>
            <p>
              <img
                src={img2}
                alt="Квартира на вторичном рынке"
                loading="lazy"
              />
            </p>
            <p className="ul-header">Советы:</p>
            <ul>
              <li>
                для расчёта стоимости ремонта лучше пригласить профессионалов.
                Иначе может оказаться, что расходы превысят полученную
                в&nbsp;итоге прибыль;
              </li>
              <li>
                после завершения ремонта, на&nbsp;тот период, пока квартира
                продается, её&nbsp;можно сдавать в&nbsp;аренду посуточно
                (придётся оборудовать её&nbsp;необходимым минимумом мебели
                и&nbsp;техники). Так она будет приносить дополнительный доход
                и&nbsp;покрывать убытки по&nbsp;оплате кредита;
              </li>
              <li>
                владельцу лучше застраховать риски признания сделки
                недействительной (например, на&nbsp;случай неожиданного
                появления наследников).
              </li>
            </ul>
            <p>
              Все эти риски вы&nbsp;можете помочь минимизировать вашему клиенту,
              предоставив соответствующие оценки, расчёты и&nbsp;консультации.
            </p>
          </section>
          <section className="page--section">
            <h2>Сдача ипотечного жилья</h2>
            <p>
              <img src={img3} alt="Сдача ипотечного жилья" loading="lazy" />
            </p>
            <p className="ul-header">Порядок действий:</p>
            <ul>
              <li>
                Ваш клиент оформляет ипотечный кредит на&nbsp;квартиру
                в&nbsp;новостройке или на&nbsp;вторичном рынке.
              </li>
              <li>
                Делает адекватный по&nbsp;стоимости ремонт, не&nbsp;слишком
                дорогой.
              </li>
              <li>Сдает в&nbsp;аренду.</li>
              <li>
                Арендными платежами покрывает выплаты по&nbsp;кредиту и&nbsp;ещё
                остается в&nbsp;плюсе, который также направляет
                на&nbsp;погашение займа.
              </li>
              <li>
                Постепенно досрочно выплачивает ипотеку&nbsp;&mdash; всё,
                у&nbsp;него есть квартира, которую можно продать или&nbsp;же
                оставить себе.
              </li>
            </ul>
            <p>
              Как&nbsp;вы, наверное, уже поняли, чтобы заработать таким
              способом, арендная плата должна хотя&nbsp;бы немного превышать
              ежемесячный платёж по&nbsp;ипотечному кредиту.
            </p>
            <p>
              Здесь решающим фактором становится сумма первоначального взноса:
              чем она больше, тем меньше будут ежемесячные выплаты.
            </p>
            <p className="ul-header">Советы:</p>
            <ul>
              <li>
                перед оформлением ипотеки нужно убедиться, что договор
                предусматривает возможность сдачи залогового жилья
                в&nbsp;аренду: некоторые банки это запрещают;
              </li>
              <li>
                помимо суммы первоначального взноса необходимо предусмотреть
                и&nbsp;&laquo;подушку безопасности&raquo;. Если по&nbsp;какой-то
                причине квартира не&nbsp;будет сдаваться в&nbsp;аренду
                2&minus;3&nbsp;месяца, платить банку всё равно придется: эти
                деньги нужно зарезервировать;
              </li>
              <li>
                гораздо выгоднее сдавать в&nbsp;аренду небольшие квартиры;
              </li>
              <li>
                владельцу лучше позаботиться о&nbsp;сохранности имущества:
                заселять квартирантов по&nbsp;официальному договору,
                с&nbsp;актом-приема передачи и&nbsp;ответственностью
                за&nbsp;ущерб.
              </li>
            </ul>
          </section>
          <section className="page--section">
            <h2>Схема заработка на ипотеке в 2020 для профессионалов</h2>
            <p>
              Если вы&nbsp;ипотечный специалист, брокер, риэлтор, сотрудник
              агентства недвижимости, для заработка в&nbsp;2020 году можно
              использовать сервис &laquo;
              <a href="/" target="_blank">
                Ипотека.Центр
              </a>
              &raquo;. Это автоматизированная платформа с&nbsp;удобным
              и&nbsp;понятным интерфейсом, к&nbsp;которой подключены банки,
              страховые и&nbsp;оценочные компании. С&nbsp;её&nbsp;помощью
              вы&nbsp;можете упростить и&nbsp;автоматизировать работу
              по&nbsp;подбору программы ипотеки или предложить эту услугу
              клиентам, если раньше у&nbsp;вас её&nbsp;не&nbsp;было.
            </p>
            <p>
              <img
                src={img4}
                alt="Схема заработка на ипотеке в 2020 для профессионалов"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              Платформа позволяет полностью пройти все этапы подготовки
              к&nbsp;оформлению ипотеки в&nbsp;режиме онлайн:
            </p>
            <ul>
              <li>
                выбрать оптимальное для клиента предложение среди всех
                представленных в&nbsp;сервисе банков;
              </li>
              <li>
                заполнить и&nbsp;подать типовую заявку-анкету на&nbsp;ипотечный
                кредит (единую для всех банков!);
              </li>
              <li>
                получать уведомление о&nbsp;том, что заявку одобрили (или
                отклонили);
              </li>
              <li>
                здесь&nbsp;же, в&nbsp;едином окне, оценить объект недвижимости,
                выбрав компанию для оценки;
              </li>
              <li>после оценки&nbsp;&mdash; застраховать;</li>
              <li>
                и&nbsp;на&nbsp;последнем этапе&nbsp;&mdash; назначить
                завершающую встречу клиента с&nbsp;банковским специалистом для
                оформления договора.
              </li>
            </ul>
            <p>
              Огромное преимущество сервиса и&nbsp;в&nbsp;том, что платформа
              предоставляет обучение. Персональный куратор, вебинары,
              мастер-классы, бизнес-завтраки с&nbsp;ведущими специалистами
              по&nbsp;недвижимости&nbsp;&mdash; все это позволит вам получать
              опыт и&nbsp;советы от&nbsp;экспертов.
            </p>
            <p>
              Попробуйте и&nbsp;оцените, как просто теперь ориентироваться
              в&nbsp;многообразии программ по&nbsp;приобретению недвижимости,
              оформлять документы и&nbsp;взаимодействовать с&nbsp;банками!
            </p>
          </section>
        </article>
      </div>
    </>
  )
}

export default HowToMakeMoneyOnMortgage
